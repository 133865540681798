var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('label',{class:[
      'mr-4',
      { 'cursor-pointer': _vm.canVote },
      { 'cursor-not-allowed': !_vm.canVote } ],on:{"click":_vm.upvote}},[_c('input',{staticClass:"vote-up invisible absolute",attrs:{"type":"radio","name":_vm.voteId,"disabled":!_vm.canVote},domProps:{"checked":_vm.hasUpvoted(_vm.proposal, _vm.currentUser)}}),_c('div',{staticClass:"border transition group flex h-10 items-center rounded-sm border-neutral-100 pl-1 pr-3 text-xs duration-200 hover:border-neutral-200"},[_c('div',{staticClass:"thumbs relative h-8 w-8"},[_c('IconThumbsUpOutline',{class:[
            'transition absolute top-0 h-8 h-8 w-8 w-8 text-neutral-300 duration-200',
            { 'group-hover:text-success-600': _vm.canVote } ]}),_c('IconThumbsUpFill',{staticClass:"transition absolute top-0 h-8 w-8 text-success-600 duration-200"})],1),_c('span',{staticClass:"pl-1 font-medium text-neutral-600"},[_vm._v(" "+_vm._s(_vm.upvotes(_vm.proposal))+" ")])])]),_c('label',{class:[
      { 'cursor-pointer': _vm.canVote },
      { 'cursor-not-allowed': !_vm.canVote } ],on:{"click":_vm.downvote}},[_c('input',{staticClass:"vote-down invisible absolute",attrs:{"type":"radio","name":_vm.voteId,"disabled":!_vm.canVote},domProps:{"checked":_vm.hasDownvoted(_vm.proposal, _vm.currentUser)}}),_c('div',{staticClass:"border transition group flex h-10 items-center rounded-sm border-neutral-100 pl-1 pr-3 text-xs duration-200 hover:border-neutral-200"},[_c('div',{staticClass:"thumbs relative h-8 w-8"},[_c('IconThumbsDownOutline',{class:[
            'transition absolute top-0 h-8 h-8 w-8 w-8 text-neutral-300 duration-200',
            { 'group-hover:text-danger-600': _vm.canVote } ]}),_c('IconThumbsDownFill',{staticClass:"transition absolute top-0 h-8 w-8 text-danger-600 duration-200"})],1),_c('span',{staticClass:"pl-1 font-medium text-neutral-600"},[_vm._v(" "+_vm._s(_vm.downvotes(_vm.proposal))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }