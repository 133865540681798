<template>
  <div
    :class="[
      'status-label inline-flex h-6 items-center rounded-full px-2 text-xs font-medium',
      { 'bg-primary-50 text-primary-600': status === 'OPEN' },
      { 'bg-neutral-50 text-neutral-600': status === 'CLOSED' },
      { 'bg-success-50 text-success-600': status === 'ACCEPTED' },
      { 'bg-danger-50 text-danger-600': status === 'DECLINED' },
    ]"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
