<template>
  <div class="flex">
    <label
      :class="[
        'mr-4',
        { 'cursor-pointer': canVote },
        { 'cursor-not-allowed': !canVote },
      ]"
      @click="upvote"
    >
      <input
        type="radio"
        :name="voteId"
        class="vote-up invisible absolute"
        :checked="hasUpvoted(proposal, currentUser)"
        :disabled="!canVote"
      />
      <div
        class="border transition group flex h-10 items-center rounded-sm border-neutral-100 pl-1 pr-3 text-xs duration-200 hover:border-neutral-200"
      >
        <div class="thumbs relative h-8 w-8">
          <IconThumbsUpOutline
            :class="[
              'transition absolute top-0 h-8 h-8 w-8 w-8 text-neutral-300 duration-200',
              { 'group-hover:text-success-600': canVote },
            ]"
          />
          <IconThumbsUpFill
            class="transition absolute top-0 h-8 w-8 text-success-600 duration-200"
          />
        </div>
        <span class="pl-1 font-medium text-neutral-600">
          {{ upvotes(proposal) }}
        </span>
      </div>
    </label>
    <label
      :class="[
        { 'cursor-pointer': canVote },
        { 'cursor-not-allowed': !canVote },
      ]"
      @click="downvote"
    >
      <input
        type="radio"
        :name="voteId"
        class="vote-down invisible absolute"
        :checked="hasDownvoted(proposal, currentUser)"
        :disabled="!canVote"
      />
      <div
        class="border transition group flex h-10 items-center rounded-sm border-neutral-100 pl-1 pr-3 text-xs duration-200 hover:border-neutral-200"
      >
        <div class="thumbs relative h-8 w-8">
          <IconThumbsDownOutline
            :class="[
              'transition absolute top-0 h-8 h-8 w-8 w-8 text-neutral-300 duration-200',
              { 'group-hover:text-danger-600': canVote },
            ]"
          />
          <IconThumbsDownFill
            class="transition absolute top-0 h-8 w-8 text-danger-600 duration-200"
          />
        </div>
        <span class="pl-1 font-medium text-neutral-600">
          {{ downvotes(proposal) }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("dishProposals", [
      "upvotes",
      "downvotes",
      "hasUpvoted",
      "hasDownvoted",
    ]),
    ...mapGetters("user", ["currentUser"]),

    voteId() {
      return `vote-${this.proposal.uuid}`;
    },

    canVote() {
      return (
        this.proposal.status === "OPEN" &&
        this.proposal.user.uuid !== this.currentUser.uuid
      );
    },
  },

  methods: {
    ...mapActions("dishProposals", [
      "upvoteDishProposal",
      "downvoteDishProposal",
    ]),

    upvote() {
      if (this.canVote) {
        this.upvoteDishProposal(this.proposal);
      }
    },

    downvote() {
      if (this.canVote) {
        this.downvoteDishProposal(this.proposal);
      }
    },
  },
};
</script>

<style scoped>
@keyframes like {
  50% {
    transform: rotate(15deg) scale(0.95);
  }
}
@keyframes dislike {
  50% {
    transform: rotate(-15deg) scale(0.95);
  }
}
.vote-up:checked + div {
  @apply border-transparent bg-success-50;
}
.vote-up:checked + div span {
  @apply text-success-600;
}

.vote-up:checked + div .thumbs svg:nth-child(1),
.vote-up + div .thumbs svg:nth-child(2),
.vote-down:checked + div .thumbs svg:nth-child(1),
.vote-down + div .thumbs svg:nth-child(2) {
  @apply opacity-0;
}
.vote-up + div .thumbs svg:nth-child(1),
.vote-up:checked + div .thumbs svg:nth-child(2),
.vote-down + div .thumbs svg:nth-child(1),
.vote-down:checked + div .thumbs svg:nth-child(2) {
  @apply opacity-100;
}
.vote-up:checked + div .thumbs svg:nth-child(2) {
  animation: 0.4s like forwards;
}

.vote-down:checked + div .thumbs svg:nth-child(2) {
  animation: 0.4s dislike forwards;
}

.vote-down:checked + div {
  @apply border-transparent bg-danger-50;
}
.vote-down:checked + div span {
  @apply text-danger-600;
}
</style>
