<template>
  <div
    :class="[
      'border rounded-sm border-neutral-50 bg-white',
      { 'mb-5': commentsVisible },
    ]"
  >
    <ProposalMeta :proposal="proposal" />
    <div v-if="proposal.coverImage" class="relative bg-neutral-50 pt-1/2">
      <div
        class="absolute top-0 left-0 h-full w-full bg-cover bg-center"
        :style="{ backgroundImage: `url(${proposal.coverImage.url})` }"
      ></div>
    </div>
    <div class="px-5 py-6 md:px-8">
      <div class="mb-8">
        <div class="mb-1 text-xs text-neutral-600">
          {{ proposal.course.name }}
        </div>
        <div class="mb-2 text-lg font-medium">{{ proposal.name }}</div>
        <div class="text-sm text-neutral-600">{{ proposal.description }}</div>
      </div>
      <div class="mb-8 items-center justify-between md:flex">
        <ProposalVotes :proposal="proposal" />
        <div
          v-if="proposal.comments.length > 0"
          class="transition mt-5 cursor-pointer text-sm font-medium text-neutral-600 duration-200 hover:text-neutral-900 md:mt-0"
          @click="toggleComments"
        >
          {{
            $t("components.community.commentsAmount", {
              amount: proposal.comments.length,
            })
          }}
        </div>
      </div>
      <ProposalReview v-if="isReviewed" :proposal="proposal" />
      <ProposalCommentForm
        :proposal="proposal"
        @afterCommentAdded="openComments"
      />
    </div>
    <ProposalComments
      v-if="commentsVisible"
      :comments="proposal.comments"
      @close="closeComments"
    />
  </div>
</template>

<script>
import ProposalMeta from "@/components/community/ProposalMeta";
import ProposalVotes from "@/components/community/ProposalVotes";
import ProposalReview from "@/components/community/ProposalReview";
import ProposalCommentForm from "@/components/community/ProposalCommentForm";
import ProposalComments from "@/components/community/ProposalComments";

export default {
  components: {
    ProposalMeta,
    ProposalVotes,
    ProposalReview,
    ProposalCommentForm,
    ProposalComments,
  },

  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      commentsVisible: false,
    };
  },

  computed: {
    isReviewed() {
      return (
        this.proposal.status === "ACCEPTED" ||
        this.proposal.status === "DECLINED"
      );
    },
  },

  methods: {
    toggleComments() {
      this.commentsVisible = !this.commentsVisible;
    },
    openComments() {
      this.commentsVisible = true;
    },
    closeComments() {
      this.commentsVisible = false;
    },
  },
};
</script>
