<template>
  <div>
    <IconTick v-if="result" class="text-success-600" />
    <IconClose v-else class="text-danger-600" />
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
