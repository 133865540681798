<template>
  <div class="mt-6 mb-2">
    <div class="relative">
      <textarea
        v-model="commentText"
        class="border focus:outline-none block h-12 w-full resize-none appearance-none rounded-sm border-neutral-100 bg-white px-4 pt-3 text-sm text-neutral-900 placeholder-neutral-400 transition-all duration-150 focus:border-neutral-300"
        :placeholder="$t(`components.community.commentPlaceholder`)"
        maxlength="280"
        required
      ></textarea>
      <button
        class="focus:outline-none absolute top-0 right-0 flex h-12 w-12 appearance-none items-center justify-center text-neutral-300 focus:border-2 focus:border-primary-100"
        @click="comment"
      >
        <IconSend />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      commentText: "",
    };
  },

  methods: {
    ...mapActions("dishProposals", ["addComment"]),

    comment() {
      if (this.commentText.length > 0) {
        this.$emit("afterCommentAdded");
        this.addComment({
          dishProposalUuid: this.proposal.uuid,
          text: this.commentText,
        });
        this.commentText = "";
      }
    },
  },
};
</script>

<style scoped>
textarea:valid {
  @apply h-24;
}
textarea:valid + button {
  @apply text-primary-600;
}
textarea:required,
textarea:invalid {
  box-shadow: none;
}
</style>
