<template>
  <div
    class="animation-default border-t-4 border-neutral-50 px-5 pt-6 animation-fadeIn animation-once md:px-8"
  >
    <div>
      <div
        v-for="comment in comments"
        :key="comment.index"
        class="comment-item flex pt-5 text-sm first:pt-0"
      >
        <div class="mr-3 flex-shrink-0">
          <UserAvatar :user="comment.user" size="sm" />
        </div>
        <div
          class="comment-item-caption border-b flex-grow border-neutral-50 pt-1 pb-5"
        >
          <div class="mb-2">
            <span class="mr-1 font-medium text-neutral-900">
              {{ fullName(comment.user) }}
            </span>
            <span class="text-xs text-neutral-400">
              {{
                $t("components.community.onDate", {
                  date: createdAt(comment.createdAt),
                })
              }}
            </span>
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="text-neutral-600" v-html="nl2br(comment.text)"></p>
        </div>
      </div>
    </div>
    <div class="relative top-4 flex justify-center">
      <BaseButton
        class="bg-white"
        :text="$t(`components.community.closeComments`)"
        size="small"
        variant="outline"
        @buttonClick="close"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";

export default {
  components: {
    UserAvatar,
  },

  props: {
    comments: {
      type: Array,
      required: true,
    },
  },

  methods: {
    createdAt(dateTime) {
      const date = new Date(dateTime);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    nl2br(text) {
      if (typeof text === "undefined" || text === null) {
        return "";
      }
      const breakTag = "<br/>";
      return `${text}`.replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        `$1${breakTag}$2`,
      );
    },
    fullName(user) {
      let fullName = "";

      if (user.firstName && user.firstName.length > 0) {
        fullName += user.firstName;
      }

      if (user.lastName && user.lastName.length > 0) {
        fullName += ` ${user.lastName}`;
      }

      if (fullName === "") {
        fullName = "?";
      }

      return fullName;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.comment-item:last-child .comment-item-caption {
  @apply border-b-0;
}
</style>
