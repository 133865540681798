<template>
  <div class="border rounded mt-8 mb-3 border-neutral-50 bg-neutral-0 p-3">
    <div class="mb-3 flex items-center">
      <Logo class="mr-2 h-6 w-6 flex-shrink-0" />
      <div class="text-xs font-medium text-neutral-900">
        {{ $t("components.community.reviewByAdmin") }}
      </div>
    </div>
    <p v-if="proposal.review.text" class="text-xs text-neutral-600">
      {{ proposal.review.text }}
    </p>
    <div class="mt-5 grid grid-cols-2 gap-4 text-xs md:grid-cols-4 md:gap-2">
      <div v-for="reviewItem in reviewItems" :key="reviewItem.index">
        <div class="text-neutral-400">
          {{ reviewItem.title }}
        </div>
        <div class="flex items-center font-medium text-neutral-900">
          <span>{{ reviewItem.text }}</span>
          <ProposalResultIcon :result="reviewItem.boolean" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProposalResultIcon from "@/components/community/ProposalResultIcon";

export default {
  components: {
    ProposalResultIcon,
  },
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reviewItems: [
        {
          title: this.$t("components.community.generalConcept"),
          text: this.proposal?.review?.generalConceptApproved
            ? this.$t("components.community.approved")
            : this.$t("components.community.declined"),
          boolean: this.proposal?.review?.generalConceptApproved || false,
        },
        {
          title: this.$t("components.community.estimatedPrice"),
          text: `€${this.proposal.estimatedPrice}`,
          boolean: this.proposal?.review?.estimatedPriceApproved || false,
        },
        {
          title: this.$t("components.community.productMix"),
          text: `${this.proposal.productMix}%`,
          boolean: this.proposal?.review?.productMixApproved || false,
        },
        {
          title: this.$t("components.community.duration"),
          text: this.proposal.duration,
          boolean: this.proposal?.review?.durationApproved || false,
        },
      ],
    };
  },
};
</script>
