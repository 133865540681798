<template>
  <div class="top-4 lg:sticky">
    <div
      v-if="
        currentUser.firstName &&
        currentUser.lastName &&
        currentUser.organisation
      "
      class="relative mb-4 flex items-center"
    >
      <UserAvatar :user="currentUser" />
      <div class="min-w-0 pl-3">
        <div class="truncate text-sm font-medium">
          {{ `${currentUser.firstName} ${currentUser.lastName}` }}
        </div>
        <div class="text-sm text-neutral-400">
          {{ currentUser.organisation.name }}
        </div>
      </div>
    </div>
    <slot></slot>
    <p class="mb-4 text-xs text-neutral-600">
      <span class="font-medium text-neutral-900">
        {{ $t("components.community.joinTheConversation") }}
      </span>
      <br />
      {{ $t("components.community.joinTheConversationDescription") }}
    </p>
    <BaseButton
      size="medium"
      variant="fill"
      :text="$t(`components.menus.proposeDish`)"
      class="w-full"
      @buttonClick="openModalCreateProposal"
    >
      <template #iconLeft>
        <IconPropose class="mr-0" />
      </template>
      <template #iconRight>
        <IconPropose class="opacity-0" />
      </template>
    </BaseButton>

    <transition name="fade">
      <ProposalModalCreate v-if="modalProposalCreateOpen" @close="closeModal" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserAvatar from "@/components/common/UserAvatar";
import ProposalModalCreate from "@/components/menus/proposal/ProposalModalCreate";

export default {
  components: {
    UserAvatar,
    ProposalModalCreate,
  },
  data() {
    return {
      modalProposalCreateOpen: false,
    };
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),
  },

  methods: {
    openModalCreateProposal() {
      this.modalProposalCreateOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    closeModal() {
      this.modalProposalCreateOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
