<template>
  <div class="border-b flex items-center border-neutral-50 px-5 py-4">
    <div class="flex-grow flex items-center">
      <UserAvatar :user="proposal.user" size="xs" />
      <div class="ml-2 text-xs">
        {{ userName }}
      </div>
    </div>
    <div class="flex items-center">
      <div class="hidden text-xs text-neutral-400 md:flex">
        <span v-if="proposal.status === 'OPEN'">
          {{
            $t("components.community.endDate", {
              date: endDate,
            })
          }}
        </span>
        <span v-else-if="proposal.status === 'CLOSED'">
          {{ $t("components.community.pendingReview") }}
        </span>
      </div>
      <StatusLabel
        :status="proposal.status"
        :text="$t(`components.community.${proposal.status.toLowerCase()}`)"
        class="ml-2"
      />
      <OverflowMenu
        v-if="canEdit"
        class="ml-3"
        :items="!editDueDatePassed ? menuItems : []"
      >
        <div
          v-if="editDueDatePassed"
          class="leading-tight flex w-48 pl-2 pr-5 text-xs text-neutral-600"
        >
          <IconLockClosed
            class="mr-2 mt-1 h-4 w-4 flex-shrink-0 text-neutral-300"
          />
          <div>
            {{ $t("components.community.editDueDatePassed") }}
          </div>
        </div>
      </OverflowMenu>
    </div>
    <transition name="fade">
      <ProposalModalEdit
        v-if="modalProposalEditOpen"
        :current-dish-proposal="proposal"
        @close="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";
import StatusLabel from "@/components/common/StatusLabel";
import OverflowMenu from "@/components/common/OverflowMenu";
import ProposalModalEdit from "@/components/menus/proposal/ProposalModalEdit";

import { mapGetters } from "vuex";

export default {
  components: {
    UserAvatar,
    StatusLabel,
    OverflowMenu,
    ProposalModalEdit,
  },
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      endDate: this.formatDate(this.proposal.endDate),
      menuItems: [
        {
          name: this.$t("global.editDetails"),
          showIf: true,
          callback: () => {
            this.openModalEditProposal();
          },
        },
      ],
      modalProposalEditOpen: false,
    };
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),
    userName() {
      const proposal = this.proposal;
      return proposal.user.firstName && proposal.user.lastName
        ? `${proposal.user.firstName} ${proposal.user.lastName}`
        : proposal.user.email;
    },
    canEdit() {
      return this.proposal.user.uuid === this.currentUser.uuid;
    },
    editDueDatePassed() {
      const createdAt = new Date(this.proposal.createdAt);
      const editDueDate = createdAt.setDate(createdAt.getDate() + 1);
      const currentDate = new Date().getTime();
      return currentDate > editDueDate;
    },
  },
  methods: {
    formatDate(date) {
      const parsedDate = new Date(date);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(parsedDate);
    },

    openModalEditProposal() {
      this.modalProposalEditOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    closeModal() {
      this.modalProposalEditOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
